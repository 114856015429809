#app {
  height: inherit;
}

/* PAGE */
.page {
  min-height: 100vh;
  margin-top: 0px;
}

.top-page-spacing {
  padding-top: var(--topNavigationMenuHeight);
}

/* PAGE SLIDE */
.page > div .slide {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  background-size: auto 100%;
  background-position: top right;
  background-repeat: no-repeat;
}

/* PAGE SLIDE CONTENT */
.page > div .slide > .content {
  z-index: 3;
  width: 42%;
  max-width: 700px;
  padding-left: 100px;
  color: var(--primaryColor);
}
.page > div .slide {
  box-shadow: inset 50vw 0vh 8em -8em rgba(255, 255, 255, 1);
}

/* PAGE SLIDE CONTENT TITLE */
.page > div .slide .content .title h1 {
  line-height: 70px;
  padding-bottom: 20px;
}
.page > div .slide .content .title h2 {
  line-height: 45px;
  padding-bottom: 20px;
}

/* GRADIENT */
.page > div .slide .content .title h1,
.page > div .slide .content .title h2,
h1.gradient,
h2.gradient {
  background: -webkit-linear-gradient(45deg, var(--primaryColor), var(--secondaryColor));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

/* PAGE SLIDE CONTENT DESCRIPTION */
.page > div .slide .content .description {
  min-height: 100px;
  font-size: 16px;
  line-height: 23px;
}
.page > div .slide .content .description .buttons {
  display: inline-flex;
  flex-wrap: wrap;
}
.page > div .slide .content .description .buttons > * {
  margin: 5px 0px;
  padding-right: 10px;
}
.page > div .slide .content .description .buttons .homedrop.postcode {
  margin: unset;
}
.page > div .slide .content .description ul li {
  margin-left: -20px;
}

/* Media queries */
@media only screen and (max-width: 1024px) {
  .page > div .slide {
    box-shadow: none;
    align-items: flex-end;
  }

  .page > div .slide > .content {
    width: 100%;
    background: #fff;
  }
}

@media only screen and (max-width: 1280px) {
  .page > div .slide > .content {
    max-width: none;
    padding: 20px 50px;
  }

  .page > div .slide > .content .title h1,
  .page > div .slide > .content .title h2 {
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 850px) {
  .page > div .slide {
    background-size: 100%;
  }
  .page > div .slide > .content {
    margin-top: 100%;
  }
  .page > div.fullfibre > .slide > .content {
    margin-top: 0;
  }
  .page > div.fullfibre > .slide:first-child > .content {
    margin-top: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .page > div .slide > .content .title h1,
  .page > div .slide > .content .title h2 {
    font-size: 34px;
    line-height: 34px;
  }

  .fullfibre .slide .images {
    flex-wrap: unset;
  }
  .fullfibre .slide .images img {
    width: 100%;
    padding: 10px;
  }
}

/* Other common classes  */
.overflow-hidden {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.vertically-horizontally-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
