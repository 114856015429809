.service-message {
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-align-items: center;
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.service-message h4 {
  color: var(--primaryColor);
}